.logoimg {
  width: 70px;
  height: 45px;
}

.bg-blur {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("/src/assets/blazer-blur.jpg");
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.ca {
  margin: 20px 0;
}

@media (min-height: 768px) {
  .logoimg {
    width: 80px;
    height: 50px;
  }

  .ca {
    height: 500px;
  }
}

@media (min-height: 1024px) {
  .logoimg {
    width: 95px;
    height: 55px;
  }
}
